section.buyerindex {
    @include breakpoint(sm) {
        //background: $lightgrey
        .buyerindex__img {
            display: none; }
        .content-card {
            padding-top: 230px; }
        h1 {
            color: $darkgreen; }
        .price-bubble {
            position: absolute;
            top: 40px;
            width: 50%;
            font-size: 20px;
            .number {
                font-size: 60px; } } } }

.buyerindex {
    &__map {
        background: center / cover no-repeat;
        min-height: 400px;
        max-height: 800px;
        height: 100vw; }
    .content-card {
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        //overflow: hidden
        h4 {
            margin-bottom: 0.25rem; } } }
