section.quotes {}

.quotes {
	&__container {
		// margin-top: 120px
		margin-bottom: 60px; }
	&__quote {
		padding: 40px;
		margin: 0 40px;
		box-shadow: 0 1px 0 0 lightgrey;
		border-radius: 5px;
		min-height: 300px;
		margin-top: 100px;
		margin-bottom: 60px;
		cursor: grab;
		&:focus, &:active {
			cursor: grabbing; }
		@include breakpoint(md) {
			margin-bottom: 50px;
			min-height: 150px; }
		@include breakpoint(sm) {
			margin-left: 0;
			margin-right: 0; }
		img {
			margin: -120px auto 40px;
			display: block;
			border-radius: 50%;
			border: 12px solid white; }
		strong {
			display: block; } }
	.button {
		display: block; } }
