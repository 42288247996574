
.grid {
    &__title {
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        justify-content: flex-end;
        height: 100%; }
    &__filter {
        @include breakpoint(md) {
            height: 100%;
            display: flex;
            flex-flow: column;
            align-items: flex-start;
            justify-content: flex-end; }
        @include breakpoint(sm) {
            margin-top: 20px; }

        &__date {
            position: relative;
            height: 50px;
            width: 100%;
            max-width: 220px;
            @include breakpoint(sm) {
                width: 100%;
                max-width: 100%; }
            &:after {
                content: '';
                height: 30px;
                width: 1.5px;
                background: darken($lightgrey, 7%);
                position: absolute;
                right: 50px;
                top: 10px; }

            &__select {
                width: 100%;
                min-width: 100px;
                color: $lightgreen;
                @extend .button;
                background: $lightgrey;
                margin: 0;
                padding: 0 10px;
 }                // option

            // 	padding-left: 10px
            svg {
                pointer-events: none;
                position: absolute;
                right: 0;
                top: 0;
                height: 50px;
                width: 50px;
                padding: 17px;
                stroke: $lightgreen;
                transform: rotate(90deg);
                stroke-width: 1px; } } }

    &__view {
        display: flex;
        height: 100%;
        width: 100%;
        align-items: flex-end;
        &__button {
            @include shadow;
            height: 50px;
            width: 50%;
            min-width: 125px;
            @extend .button;
            border-radius: 0;
            box-shadow: 0 1px 0 0 #00A649;
            @include breakpoint(md) {
                min-width: 0; }
            &:hover {
                box-shadow: 0 1px 1px 1px #00A649; }

            &:first-child {
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px; }

            &:last-child {
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px; } } }
    &--overview {
        background: $darkgreen;
        .grid-map-is-active & {
            background: $lightgreen; } }
    &--map {
        background: $lightgreen;
        .grid-map-is-active & {
            background: $darkgreen; } } }

.apartments-grid {
    &__wrapper {
        position: relative;
        // overflow-x: hidden
        .grid-map-is-active & {
            max-height: 1000px;
            @include breakpoint(sm) {
                max-height: 500px; } } }
    &__apartments {
        margin-top: 80px;
        border-bottom: 1px solid rgba(grey, 0.2);
        @include transition;
        will-change: opacity;
        .grid-map-is-active & {
            // transform: translate3d(-100%,0,0)
            opacity: 0; } }

    &__map {
        position: absolute;
        @include coverall;
        // margin-top: 80px
        @include transition;
        opacity: 0;
        z-index: -1;
        will-change: opacity;
        height: 100%;
        max-height: 1000px;
        @include breakpoint(sm) {
            height: 150vw;
            max-height: 500px; }
        .grid-map-is-active & {
            transform: none;
            z-index: 1;
            opacity: 1; }
        .map {
            position: absolute;
            @include coverall;
            &__pin {
                background: none;
                &__text {
                    display: none; } } } } }


.apartment-card {
    position: relative;
    margin-bottom: $gutter * 2;
    @include shadow;
    background: white;
    animation: fadeIn 0.5s ease-in forwards;
    &.is-loading {
        min-height: 533px;
        opacity: 0.2;
        animation: none; }
    &__img__wrapper {
        height: 220px;
        width: 100%;
        overflow: hidden;
        display: flex;
        align-items: flex-end;
        position: relative; }

    &__img {
        background: center / cover no-repeat;
        @include coverall;
        position: absolute;
        cursor: pointer;
        will-change: transform;
        @include transition;
        @include breakpoint(lg) {
            &:hover {
                transform: scale(1.1); } }
        .map-is-active &, .floor-plan-is-active & {
            opacity: 0; } }
    &__map, &__floor-plan {
        background: center / cover no-repeat;
        @include coverall;
        position: absolute;
        will-change: transform;
        @include transition;
        pointer-events: none;
        opacity: 0; }
    &__map {
        .map-is-active & {
            opacity: 1;
            pointer-events: all; } }
    &__floor-plan {
        .floor-plan-is-active & {
            opacity: 1;
            pointer-events: all; } }
    &__map--button, &__floor-plan--button {
        z-index: 1;
        background: $lightgreen;
        height: 50px;
        width: 50px;
        padding: 15px;
        cursor: pointer;
        @include transition;
        .icon {
            width: 20px;
            height: 20px; }
        @include breakpoint(dt) {
            &:hover {
                background: darken($lightgreen, 10%); } } }
    &__floor-plan--button {
        .floor-plan-is-active & {
            background: $darkgreen;
            .icon--map {
 } } }                // fill: $lightgreen
    &__map--button {
        border-right: 1px solid rgba( white, 0.5);
        border-right: 1px dashed $darkgreen;
        .map-is-active & {
            background: $darkgreen;
            .icon--map {
                fill: $lightgreen; } }
        .icon--map {
            fill: $darkgreen; }
        @include breakpoint(dt) {
            &:hover {
                background: darken($lightgreen, 10%); } } }

    &__info {
        // box-shadow: 0 0 11px 0 rgba(185, 185, 185, 0.5)
        padding: $gutter; }

    &__table {
        width: 100%; }

    // box-shadow: 0 0 11px 0 rgba(185,185,185,0.50)
    &__row {
        border-bottom: 1px solid rgba(grey, 0.2);
        height: 30px; }

    &__column {
        padding: 5px; }

    &__label {
        color: #AAAAAA;
        font-size: 12px;
        width: 80px; }

    &__value {
        font-size: 14px; }

    &__match-value {
        margin-left: 18px; }

    &--street {
        border-bottom: 1px solid rgba(grey, 0.3);
        display: block;
        height: 30px;
        line-height: 30px; }

    &--zip-city {
        height: 30px;
        line-height: 30px; }

    &--price {
        font-weight: 700; }

    &__link {
        height: 50px;
        @extend .flex-center;
        background: #E3E3E3;
        background: $lightgreen;
        color: white !important;
        font-weight: 700;

        @include transition;

        @include breakpoint(dt) {
            &:hover {
                background: $darkgreen; } } } }
