.tabify {
  display: block;
  list-style: none;
  width: 100%; }


.tabify-tabs {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @include breakpoint(sm) {
    min-width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around; } }


.tabify-tab {
  // min-width: 200px
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: solid 1px #728589;
  padding: 0 18px;
  &:last-of-type {
    border-right: none; }
  &[current="true"] {
    background: rgba(114, 133, 137, 0.1);
    color: #2FAB66; }
  @include breakpoint(md) {
    min-width: 180px; }
  @include breakpoint(sm) {
    font-size: 14px;
    font-weight: 700;
    line-height: 50px;
    height: 50px;
    min-width: 45%;
    margin-left: 5px;
    margin-bottom: 15px;

    // text-transform:
    border-radius: 5px;
    border: none;
    cursor: pointer;

    background: #969696;
    appearance: none;
    -webkit-appearance: none;
    color: white;
    outline: 0;
    font-weight: 700;
    border-radius: 5px;
    &[current="true"] {
      background: $lightgreen;
      color: white;
      box-shadow: 1px 0 5px #3338; } }

  @include breakpoint(xs) {
    min-width: 120px;
    width: 100%;
    min-height: 40px;
    border-right: none; } }
