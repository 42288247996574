
form {
  @include breakpoint(sm) {
    width: 100%;
    h1, h2, h3, h4 {} } }
// text-align: center
label {
  @extend p;
  padding: 0;
  margin: 0;
  font-size: 14px;
  // pointer-events: none
  @include transition;
  &[required] {
    &:after {
      content: '*';
      color: $red; } } }

input {
  outline: 0;
  &[disabled] {
    opacity: .7 !important; }
  &[type=text], &[type=password], &[type=number], &[type=email] {
    min-width: 300px;
    padding: 10px;
    border-radius: 3px;
    border: 1px solid $grey-lighter;
    width: 100%;
    @extend p;
    color: #555;
    .modal & {
      min-width: 100px; }
    @include breakpoint(dt) {
      width: 100%;
      max-width: 700px; }
    &::-webkit-input-placeholder {
      opacity: .7; }
    @include breakpoint(sm) {
      min-width: 180px;
      width: 100%; } }
  &[type=number]::-webkit-inner-spin-button, &[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; }
  &[type=submit] {
    width: 100%; } }

textarea {
  height: 100%;
  width: 100%;
  min-height: 200px;
  padding: 10px;
  color: rgba(black, 0.6);
  border: 1px solid $grey-lighter;
  .modal & {
    min-height: 100px; }
  @include breakpoint(sm) {
    min-height: 100px; } }

select {
  -webkit-appearance: none;
  background: white;
  padding: 10px;
  border-radius: 3px;
  border: 1px solid $grey-lighter;
  outline: 0;
  width: 100%;
  margin-bottom: 16px;
  @extend p;
 }  //width: 45%

.select-wrapper {
  // width: 45%
  position: relative;
  margin-bottom: 1rem;
  select {
    width: 100%; }
  svg.icon {
    position: absolute;
    right: 10px;
    top: 0;
    height: 100%;
    width: 8px;
    pointer-events: none; } }

.input-field {
  &__value {
    // font-family: Chivo-Bold
    font-weight: 700;
    font-size: 15px;
    color: $darkgreen;
    letter-spacing: 0;
    line-height: 24px;
    &--price {
      &:after {
        content: 'kr.';
        padding-left: 5px; } }
    &--size {
      &:after {
        content: 'kvm.';
        padding-left: 5px; } } }
  &__text, &__number, &__select {
    // display: inline-block
    display: table;
    position: relative;
    width: 100%;
    //+breakpoint(lg)
    &.help {
      @include breakpoint(dt) {
        width: 90%; } }
    &.has-error {
      input {
        border: 1px solid $red; } }
    // margin-bottom: 0.5rem
    @include breakpoint(sm) {
      width: 100%; }
    .icon--question {
      position: absolute;
      right: -40px;
      // top: calc(50% - 15px)
      bottom: calc(23px / 2);
      bottom: calc(46px / 2);
      height: 30px;
      width: 30px;
      @include breakpoint(lg);
      // right: -10px
      @include breakpoint(md) {
        padding: 5px;
        right: 0;
        top: 0;
        height: 20px;
        width: 20px; } } }
  &--text-bordered {
    position: relative;
    width: 100%;
    label {
      position: absolute;
      top: 0;
      prosedo {}
      foreningererforvindere {} }
    input {
      appearance: none;
      -moz-appearance: none;
      border: none;
      border-bottom: 1px solid $green;
      background: none;
      min-width: 150px;
      padding-left: 0;
      @extend p;
      &:focus, &:active, &.active {
        + label {
          top: -20px;
          color: transparentize($green, 0.5);
          font-size: 12px; } }
      @include breakpoint(md) {
        width: 100%; } } }
  &__checkbox {
    display: flex;
    display: table;
    flex-flow: row;
    align-items: center;
    margin: 10px 0;
    position: relative;
    &.flex-center {
      display: flex; }
    input {
      &[type=checkbox] {
        display: none; }
      &:checked ~ label svg,
      &[value="true"] ~ label svg {
        stroke-dashoffset: 0px; } }
    // opacity: 1
    label {
      height: 20px;
      line-height: 20px;
      margin: 0;
      vertical-align: middle;
      cursor: pointer;
      display: flex;
      align-items: center;
      position: relative;
      @include breakpoint(sm) {
        height: 25px; }
      &:before {
        content: '';
        display: inline-block;
        height: 20px;
        width: 20px;
        min-width: 20px;
        margin-right: 10px;
        border: 1px solid $green;
        border-radius: 3px;
        cursor: pointer;
        @include breakpoint(sm) {
          height: 25px;
          width: 25px; } } }
    svg {
      position: absolute;
      left: 3px;
      top: 4px;
      height: 15px;
      width: 15px;
      pointer-events: none;
      transition: all $transition linear;
      @include breakpoint(sm) {
        height: 20px;
        width: 20px; } } }
  &__photo {
    height: 150px;
    width: 150px;
    position: relative;
    border-radius: 50%;
    border: 1px solid $darkgreen;
    background: white;
    cursor: pointer;
    @extend .flex-center;
    @include transition;
    &:hover {
      background: $green; }
    &:active {
      background: $darkgreen; }
    .icon--photo {
      pointer-events: none; }
    .icon--add {
      position: absolute;
      top: 0;
      right: 0; }
    input {
      -webkit-appearance: none;
      position: absolute;
      opacity: 0;
      height: 100%;
      width: 100%;
      border: none;
      background: none;
      cursor: pointer;
      border-radius: 50%; }
    &__img {
      background: center / cover no-repeat;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      pointer-events: none;
      border-radius: 50%; } }
  &__textarea, input[name=description] {
    // height: 400px
    min-height: 200px;
    .modal & {
      min-height: 100px; }
    @include breakpoint(sm) {
      min-height: 100px;
      height: auto; } }
  &__range {
    position: relative;
    width: 100%;
    height: 80px;
    margin-bottom: 1.5rem;
    @include breakpoint(sm) {
      padding: 0 10px !important; }
    label {
      @include breakpoint(md) {
        transform: translateY(-10px); } }
    &__slider {
      position: relative;
      height: 40px;
      text-align: center;
      input[type=range]::-moz-focus-outer {
        border: 0; }
      input {
        pointer-events: none;
        position: absolute;
        overflow: hidden;
        left: 0;
        top: 15px;
        width: 100%;
        outline: none;
        height: 18px;
        margin: 0;
        padding: 0;
        &[type=range] {
          -webkit-appearance: none;
          background: white;
          border: 1px solid $grey-lighter;
          overflow: visible;
          border-radius: 5px;
          height: 10px;
          &::-webkit-slider-thumb {
            -webkit-appearance: none;
            pointer-events: all;
            position: relative;
            cursor: pointer;
            z-index: 1;
            outline: 0;
            width: 20px;
            height: 20px;
            border: none;
            border-radius: 50%;
            box-shadow: 0 0 2px 1px $grey-lighter;
            background: white;
            border: 1px solid $grey-lighter;
            @include transition;
            &:hover {
              box-shadow: 0 0 3px 2px $grey-lighter; } } } } }

    &__bg-overlay {
      position: absolute;
      background: $lightgreen;
      width: 50%;
      height: 4px;
      top: calc(50% - 2px);
      left: 50px; }

    &--min {
      position: absolute;
      left: 0;
      bottom: -20px; }
    &--max {
      position: absolute;
      bottom: -20px;
      right: 0; } }
  &__upload {
    position: relative;
    margin: 5px 0;
    max-width: 100%;
    padding: 20px 0;
    @include breakpoint(md) {
      width: 100%; }
    label {
      position: relative;
      padding: 10px;
      text-transform: initial;
      min-height: 50px;
      height: auto;
      line-height: 18px;
      padding: 10px;
      margin: 0 !important;
      @include breakpoint(dt) {
        &:hover {
          .icon--upload-arrow {
            transform: translateY(-4px); } } } }
    input {
      // display: none
      pointer-events: none;
      -webkit-appearance: none;
      position: absolute;
      opacity: 0;
      height: 100%;
      width: 100%;
      border: none;
      background: none;
      cursor: pointer; }
    p {
      color: white;
      margin: 0 30px;
      word-break: break-word; }
    .icon {
      position: absolute;
      right: 10px;
      &--upload {
        &-arrow {
          height: 20px;
          width: 20px;
          bottom: 15px; }
        &-bar {
          height: 20px;
          width: 20px;
          bottom: 0; } } } }
  &__radio {
    display: flex;
    flex-flow: row;
    align-items: center;
    margin-bottom: 10px;
    input {
      width: 10px;
      height: 10px;
      margin-right: 25px;
      appearance: none;
      border-radius: 50%;
      cursor: pointer;
      position: relative;
      flex: 0 0 10px;
      &:after {
        content: '';
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        border: 1px solid $green;
        border-radius: 50%;
        transform: scale(2);
        transform-origin: center center; }
      @include breakpoint(dt) {
        &:hover {
          transform: scale(1.1); } }
      &[value=true] {
        background: $green; } }
    label {
      order: 1;
      cursor: pointer;
      h4 {
        margin-bottom: 0;
        @include breakpoint(sm) {
          text-align: left; } } } } }

.bar {
  position: relative;
  background: #ddd;
  height: 4px !important;
  top: 2px !important;
  &.bar-0, &.bar-2 {
    display: none; }
  &.bar-1 {
    background: $green; } }

.values-container {
  width: 100%;
  height: 20px; }

.slider-component {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  width: 100%;
  // max-width: 500px
  position: relative; }
// height: 40px

.horizontal-slider {
  width: 100%;
  background: white;
  border: 1px solid $grey-lighter;
  overflow: visible;
  border-radius: 5px;
  height: 10px;
  // margin: 10px 0
  margin-top: 10px;
  cursor: pointer;
  @include breakpoint(md) {
    margin-top: 50px; }
  .thumb {
    top: -10px;
    line-height: 48px;
    -webkit-appearance: none;
    pointer-events: all;
    position: relative;
    cursor: pointer;
    z-index: 1;
    outline: 0;
    width: 30px;
    height: 30px;
    border: 1px solid black;
    border: none;
    border-radius: 50%;
    background: none;
    @include ext(flex-center);
    @include breakpoint(md) {
      height: 60px;
      width: 60px;
      top: -25px; }
    &:after {
      content: '';
      box-shadow: 0 0 2px 1px $grey-lighter;
      border: 1px solid $grey-lighter;
      background: white;
      border-radius: 50%;
      height: 21px;
      width: 21px;
      // +transition
      @include breakpoint(md) {
        height: 30px;
        width: 30px; } }
    &:hover {
      &:after {
        // box-shadow: 0 0 3px 2px $grey-lighter
        transform: scale(1.1); } }
    &-0 {
      // justify-content: flex-start
      transform: translateX(-10px);
      @include breakpoint(md) {
        transform: translateX(-20px); } }

    &-1 {
      // justify-content: flex-end
      transform: translateX(10px);
      @include breakpoint(md) {
        transform: translateX(20px); }
      @include breakpoint(sm); } }
  // transform: translateX(0px)

  .track {
    height: 10px; }
  .track-1 {
    background-color: $green; } }

.min-val, .max-val {
  @extend .input-field__value;
  bottom: -20px;
  @include breakpoint(md) {
    bottom: 55px; } }

.min-val {
  position: absolute;
  left: 0px; }

.max-val {
  position: absolute;
  right: 0px; }

.handle {
  font-size: 0.9em;
  text-align: center;
  background-color: black;
  color: white;
  &.active {} }
// background-color: grey

#horizontal-0,
#horizontal-1, #horizontal-2, #horizontal-3,
#vertical {
  margin: 20px 10px; }

.error {
  font-size: 14px;
  color: $red;
  margin-bottom: 1rem; }
