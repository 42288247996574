
.container {
    max-width: $breakpoint-xl;
    margin: 0 auto;
    position: relative;
    padding-left: $gutter;
    padding-right: $gutter;

    @include breakpoint(lg) {
        padding: 0; } }

// An example of how to add different max width of containers globally depending window width. A more "adaptive" approach instead of the fluid one
// @media (min-width: breakpoint('m'))
//     .container
//         max-width: breakpoint('m')


[class^=col-] {
    display: block;
    width: 100%;
    padding-left: ($gutter/2);
    padding-right: ($gutter/2); }


.row {
    margin-left: -($gutter/2);
    margin-right: -($gutter/2); }

@media all and (min-width: breakpoint('s')) {
    .row {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row; } }

@each $breakpoint,$value in $breakpoints {
    @media only screen and (min-width: $value) {
        .col-#{$breakpoint} {
            flex: 1 0 0;
            max-width: 100%; }
        @for $i from 1 through $grid-columns {
            .col-#{$breakpoint}-#{$i} {
                flex: 0 0 percentage($i / $grid-columns);
                max-width: percentage($i / $grid-columns); }
            .col-#{$breakpoint}-push-#{$i} {
                margin-left: percentage($i / $grid-columns); }
            .col-#{$breakpoint}-pull-#{$i} {
                margin-right: percentage($i / $grid-columns); } } } }

