section.search {
    padding: 0;
    background-color: $lightgrey; }
.search {
    &__input-field {
        @extend .flex-center;
        min-height: 150px;
        height: 100%;
        padding: $gutter*1;
        [class^="input-field"] {
            margin-bottom: 0; }
        @include breakpoint(sm) {
            padding: 0 !important; } }
    .bordered-columns {
        >* {
            padding: 0;
            @include breakpoint(md) {
                border-bottom: 1px solid $grey-lighter;
                &:nth-child(odd) {
                    border-left: none; } }
            @include breakpoint(sm) {
                padding-left: 10px;
                padding-right: 10px; }
            &:first-child {
                .search__input-field {
                    padding-left: 0.5rem;
                    @include breakpoint(md) {
                        padding-left: $gutter; } } } } }
    button {
        &[type=submit] {
            background: $lightgreen;
            -webkit-appearance: none;
            height: 70px;
            min-width: 70px;
            width: 70px;
            color: white;
            font-weight: 700;
            border-radius: 50%;
            border: none;
            position: relative;
            cursor: pointer;
            outline: 0;
            z-index: 1;
            margin: 0;
            box-shadow: 0 0 0px 10px lightgrey;
            text-transform: capitalize;
            z-index: 1;
            &:hover {
                box-shadow: 0 0 2px 13px lightgrey; }
            &:after {
                content: '';
                @include coverall;
                position: absolute;
                border-radius: 50%;
                z-index: -1;
                background: rgba(black, 0.2);
                opacity: 0;
                animation: search 2s 2s ease-out forwards;
                animation-iteration-count: 2; } } }


    &__advanced {
        display: none;
        border-top: 1px solid $grey-lighter;
        padding: $gutter 0;
        line-height: 22px;
        @extend .flex-center;
        justify-content: flex-start;
        cursor: pointer;
        svg.icon {
            height: 22px;
            width: 22px;
            padding: 5px;
            margin-right: 10px; } } }
