.flex-wrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-around; }


.flex-wrapper-evenly {
  display: flex;
  flex-flow: row nowrap;
  align-items: center; }


.flex-circle-space {
  text-align: center;
  flex: 0 0 30%;
  align-self: flex-start; }

.flex-single-circle {
  width: 60px; }

.single-chart {
  width: 100%;
  justify-content: space-around; }




.small-chart {
  width: 100%;
  display: inline-block;
  align-self: flex-start;
  text-align: center;
  @include breakpoint(dt); }



.circular-chart {
  display: block;
  max-width: 100%;
  max-height: 250px; }


.circle-bg {
  fill: none;
  stroke: #eee;
  stroke-width: 3.8; }


.circle {
  fill: none;
  stroke-width: 2.8;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards; }


@keyframes progress {
  0% {
    stroke-dasharray: 0 100; } }


.circular-chart.orange .circle {
  stroke: #ff9f00; }


.circular-chart.green .circle {
  stroke: #2FAB66; }


.circular-chart.blue .circle {
  stroke: #3c9ee5; }


.percentage {
  fill: #00D960;
  font-size: 0.5em;
  text-anchor: middle; }
