.contact {
    &__map {
        min-height: 400px;
        height: 100%;
        width: 100%;
        @include breakpoint(md) {
            margin-bottom: 80px;
            height: 400px; } }
    .map {
        width: 100;
        height: 100%; }
    &__card {
        padding: 40px;
        margin: 0 40px;
        box-shadow: 0 1px 0 0 lightgrey;
        border-radius: 5px;
        min-height: 300px;
        // margin-bottom: 60px
        @include breakpoint(md) {
            margin-top: 140px;
            margin-bottom: 50px;
            min-height: 150px;
            margin-left: 0;
            margin-right: 0;
            padding: 40px 80px; }
        @include breakpoint(sm) {
            padding: 20px;
            margin: 140px 0 60px; }
        &__img {
            margin: -120px auto 40px;
            display: block;
            border-radius: 50%;
            border: 12px solid white;
            width: 200px;
            height: 200px;
            background: white 30% 40% / cover no-repeat; }
        p {
            @include breakpoint(md) {
                max-width: 70%; }
            @include breakpoint(sm) {
                max-width: 100%; } }
        strong {
            display: block; }
        .small {
            margin-bottom: 0;
            font-size: 14px;
            padding-top: 10px;
            border-top: 1px solid rgba(white, 0.5); }
        h1 {
            font-weight: 700;
            @include breakpoint(sm) {
                text-align: left; } } } }
