.modal {
	// background: rgba(black, 0.9)
	position: fixed;
	top: 0;
	height: 100%;
	width: 100%;
	@extend .flex-center;
	z-index: 1000;
	@include transition;
	opacity: 0;
	pointer-events: none;
	@include breakpoint(sm) {
		align-items: flex-start;
		//overflow: scroll
		//iOS bug with forms and fixed position
		position: absolute; }
	&:before {
		content: '';
		@include coverall;
		position: fixed;
		background: rgba(black, 0.9);
		@include breakpoint(sm) {
			display: none; } }
	&.modal-is-open {
		opacity: 1;
		pointer-events: all;
		left: 0px; }
	h2 {
		color: $green; }
	.button {
		display: block; }
	&__inner {
		// background: $lightgrey
		background: white;
		position: relative;
		@extend .flex-center;
		flex-flow: column;
		max-width: 80%;
		max-height: 95%;
		// max-height: 80vh
		@include breakpoint(dt) {
			// min-height: 40%
			min-width: 30%;
			max-width: 50%;
			//width: 30%
			//width: 35%
 }			// padding: 80px 50px
		@include breakpoint(md) {
			// padding: 60px 40px
			margin: 20px;
			text-align: center;
			.button {
				margin: 40px auto 0; } }
		@include breakpoint(sm) {
			margin: 0;
			position: absolute;
			width: 100%;
			max-width: 100%;
			min-height: 100%; } }


	&__content {
		padding: 80px 50px 50px;
		width: 100%;
		min-height: 100%;
		overflow: scroll;
		flex: 1 1 auto;
		@include breakpoint(md) {
			padding: 60px 40px; }
		@include breakpoint(sm) {
			padding: 60px 40px;
			flex: 0 1 auto;
			//position: absolute
			overflow: visible;
			top: 0px; } }




	&__close {
		position: absolute;
		height: 40px;
		width: 40px;
		top: -10px;
		right: -10px;
		-webkit-appearance: none;
		outline: 0;
		cursor: pointer;
		border-radius: 50%;
		border: none;
		background: $lightgreen;
		box-shadow: 0 0 3px 1px grey;
		z-index: 100;
		@include breakpoint(sm) {
			top: 0px;
			right: 0px;
			border-radius: 0%;
			height: 60px;
			width: 60px; }
		&:before, &:after {
			content: '';
			position: absolute;
			width: 50%;
			height: 1px;
			top: 50%;
			left: 25%;
			background: $lightgrey; }
		&:before {
			transform: rotate(-45deg); }
		&:after {
			transform: rotate(45deg); } }
	&__forgot-password {
		margin-bottom: 40px; }
	.input-field__checkbox {
		margin-top: 20px; }
	form {
		width: 100%; }
	.input-field__number, .input-field__text {
		display: block; }

	input {
		&[type=text], &[type=password], &[type=number], &[type=email] {
			width: 100%; } } }

.modal-is-open-hide-on-small {
	@include breakpoint(sm) {
		display: none; } }
