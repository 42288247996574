.partner {
  .container {
    padding: 70px 40px 70px 40px;
    @include breakpoint(md) {
      padding: 70px 60px; }
    @include breakpoint(sm) {
      padding: 70px 8vw; } }
  &__list {
    margin-top: 30px;
    margin-bottom: 20px;
    @include breakpoint(sm) {
      margin-top: 25px; } }
  h4 {
    text-align: left; }
  ul {
    margin-bottom: 28px;
    a {
      margin-left: 5px; }
    li {
      margin-bottom: 5px; } } }
