/* Style the button that is used to open and close the collapsible content */
.collapsible {
    cursor: pointer;
    width: 100%;
    border: none;
    text-align: left;
    font-weight: bold;
    outline: none;
    font-size: 20px;
    font-family: Chivo;
    border-top: 1px solid #D0D0D0;
    color: #196A3D;
    padding: 10px 0 14px
}


/* Style the collapsible content. Note: hidden by default */

.collapsible-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
}

.collapsible-content.active {
    max-height: 600px;
    display: block;
}

.collapsible:after {
    content: '›';
    font-size: 35px;
    float: right;
    position: relative;
    top: -9px;
    transition: transform .2s ease-in-out;
    -moz-transition: transform .2s ease-in-out;
    transform-origin: 40% 50%;
    transform: rotate(0deg);

}

.collapsible.active:after {
    content: '›';
    transform-origin: 40% 50%;
    transform: rotate(90deg);
}
