@import url('https://fonts.googleapis.com/css?family=Chivo:300,400,400i,700');

html {
    font-family: 'Chivo';
    font-size: $base-font-size;
    line-height: $base-line-height;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

// Set the font config for each of the headers before hitting any breakpoints

@for $i from 1 through $amount-of-configured-headers {
    h#{$i} {
        font-size: header(h#{$i}-s);
        line-height: lineHeight(h#{$i});
        margin: 0 0 0.5em; } }

// for each breakpoint get the corresponding default header font-size. See _config.sass for values
// Also margin based on font-scale set in config.sass. Remove this if necessary.
@each $breakpoint,$value in $breakpoints {
    @media only screen and (min-width: $value) {
        @for $i from 1 through $amount-of-configured-headers {
            h#{$i} {
                font-size: header(h#{$i}-#{$breakpoint});
                line-height: lineHeight(h#{$i});
                margin: 0 0 0.5em; } } } }


h1 .main-title {
    font-weight: 400;
    @include breakpoint(sm) {
        font-weight: 700;
        font-size: rem(26px);
        text-align: center; } }
h2 {
    // font-size: 32px
    // // color: $darkgreen
 }    // line-height: 46px
h4 {
    margin-bottom: 1.5rem;
    font-weight: 700;
    @include breakpoint(sm) {
        text-align: center; } }
p {
    color: #797979;
    line-height: 23px;
    display: block; }

//
// Links
//

a {
    color: $link-color;
    display: inline-block;
    text-decoration: $link-decoration;
    background-color: transparent; // Remove the gray background on active links in IE 10.
    -webkit-text-decoration-skip: objects; // Remove gaps in links underline in iOS 8+ and Safari 8+.
    transition: color 0.5s $ease;
    &:hover {
        color: $link-hover-color;
        text-decoration: $link-hover-decoration; } }
