.sale {
	position: relative;
	&__img {}
	&__content {}
	&__height-wrapper {
		height: 150px;
		display: flex;
		flex-flow: column;
		justify-content: center;
		align-items: flex-start; }
	.checklist-item {
		&:first-child {
			border-top: 1px solid lightgrey; } } }
.selfsale {
	&__front {
		section {
			counter-reset: li;
			&:nth-child(1) {
				padding-bottom: 60px; }
			&:nth-child(2) {
				padding-top: 60px;
				position: relative; }
			&.u-darkgreen {
				@include breakpoint(dt) {
					padding: $gutter*4; }
				li {
					color: white; } } }
		.price-bubble {
			position: absolute;
			top: calc(-42px/2);
			left: calc(50% - 200px/2);
			width: 64px; } }
	&__upload {
		&__column {
			position: relative;
			margin: 5px 0;
			// padding: 20px -10
			padding: 20px;
			.icon--question {
				display: none;
				position: absolute;
				right: 10px;
				top: 10px; } }
		&__form {
			width: 100%;
			h3 {
				width: 80%;
				@extend .flex-center !optional;
				min-height: 66px; } } } }
