.swap-process-box {
  padding-top: 65px;
  padding-bottom: 75px; }

.swap-process-title {
  font-family: Helvetica;
  font-size: 45px;
  font-weight: normal;
  text-align: center;
  margin-bottom: 40px;
  @include breakpoint(md) {
    text-align: center;
    font-size: 35px; } }

.swap-process-container {
    display: flex;
    justify-content: space-between;
    @include breakpoint(md) {
      flex-direction: column;
      justify-content: center;
      align-items: center; } }

.swap-process-line {
  height: 2px;
  background: #2FAB66;
  flex: 1 1 auto;
  margin: 80px -20px 0 -20px;
  @include breakpoint(md) {
    height: 60px;
    width: 2px;
    margin: 10px 0 7px 0; } }


.swap-process-item {
  width: 200px;
  align-items: center;
  text-align: center;
  justify-content: center;
  flex: 0 1 auto;
  margin-top: 20px;
  margin-bottom: 10px;
  padding: 0px -20px;
  @include breakpoint(md); }

.swap-process-item-number {
  font-size: 32px;
  font-family: Helvetica;
  margin: 10px auto; }




