.case {
    position: relative;
    overflow: hidden;
    &__gallery-button-container {
        position: absolute;
        z-index: 1;
        left: 20px;
        top: -170px;
        @include breakpoint(lg) {
            left: 0; }
        @include breakpoint(md) {
            left: 40px;
            top: -320px; }
        @include breakpoint(sm) {
            left: auto;
            top: -90px; }
        &--selfsale {
            position: absolute;
            z-index: 1;
            left: 20px;
            top: -170px;
            @include breakpoint(lg) {
                left: 0; }
            @include breakpoint(md) {
                left: 40px; }
            @include breakpoint(sm) {
                left: auto;
                top: -90px; } } }
    &__img {
        overflow: hidden;
        height: 800px;
        @include breakpoint(md) {
            height: 600px; }
        @include breakpoint(sm) {
            height: 50vw; }

        .case {
            &__slider {
                &__slide {
                    align-items: inherit;
                    &__img {
                        width: 100%;
                        max-width: 100%;
                        max-height: 800px;
                        object-fit: cover;
                        display: block;
                        @include breakpoint(md) {
                            max-height: 600px; }
                        @include breakpoint(sm) {
                            max-height: 50vw; } }
                    &__full {
                        display: none; } } } }
        .slick-slider {
            height: 100%;
            .slick-dots {
                display: none !important; } }
        .slider {
            align-items: center;
            &--prev, &--next {
                z-index: 15;
                @include breakpoint(xl-m) {
                    width: 55px;
                    height: 55px;
                    top: 4rem;
                    background: none; }
                @include breakpoint(md) {
                    top: 2rem; }
                @include breakpoint(sm) {
                    top: 1rem; } }
            &--prev {
                left: 1.5rem;
                @include breakpoint(sm) {
                    right: 95px;
                    left: auto;
                    bottom: 35px;
                    top: auto; } }
            &--next {
                right: 1.5rem;
                @include breakpoint(sm) {
                    right: 30px;
                    bottom: 35px;
                    top: auto; } } } }

    &__content {
        position: relative;
        padding-right: 5%;
        @include breakpoint(sm) {
            padding-right: 0; } }
    &__wrapper {
        position: relative;
        @include breakpoint(sm) {
            position: static; } }
    &__slider {
        position: fixed;
        @include coverall;
        top: 0;
        left: 0;
        z-index: 200;
        background: rgba($dark-grey, 0.9);
        transition: transform 0.6s $ease, z-index 0s 0.6s;
        transform: translateX(100%);
        z-index: -100;
        height: 95vh;
        height: calc( 100vh - 100px);
        height: 100vh;

        &.is-active {
            opacity: 1;
            transform: none;
            z-index: 1000;
            transition: transform 0.6s $ease, z-index 0s;
            pointer-events: all; }

        &__button {
            &--open {
                position: relative;
                display: inline-block;
                width: 190px;
                padding-left: 15px;
                margin-right: 30px;
                text-align: left;
                @include breakpoint(md) {
                    left: 40;
                    display: block;
                    margin: 20px 0; }
                @include breakpoint(sm) {
                    display: inline-block;
                    margin: 0 15px 0 0;
                    width: 30px;
                    text-indent: -9999px;
                    min-width: 50px; } }
            &--open .icon {
                position: absolute;
                width: 28px;
                height: 30px;
                top: 23%;
                right: 15px;
                @include breakpoint(sm) {
                    right: 13px; } }
            &--last .icon {
                width: 24px;
                height: 24px;
                top: 28%; }

            &--close {
                position: absolute;
                top: 0;
                right: 0;
                height: 100px;
                width: 100px;
                background: $bluegrey;
                @include transition;
                cursor: pointer;
                @include breakpoint(md) {
                    height: 80px;
                    width: 80px; }
                @include breakpoint(sm) {
                    height: 70px;
                    width: 70px;
 }                    // top: 70px
                &:after, &:before {
                    content: '';
                    position: absolute;
                    left: 25%;
                    top: 50%;
                    background: white;
                    height: 2px;
                    width: 50%;
                    @include transition;
                    transform-origin: center;
                    will-change: transform; }
                &:before {
                    transform: rotate(-45deg); }
                &:after {
                    transform: rotate(45deg); }
                &:hover {
                    &:before {
                        transform: rotate(-45deg) scale(1.1); }
                    &:after {
                        transform: rotate(45deg) scale(1.1); } } } }

        &__slide {
            @include ext(flex-center);
            width: 100%;
            align-items: center;
            height: 100vh;
            cursor: grab;
            &:active, &:focus {
                cursor: grabbing; }
            &__img {
                max-width: 100%;
                width: 100%;
                height: auto;
                pointer-events: none;
                @include breakpoint(sm) {
                    .viewport-wide & {
 } }                        // display: none !important
                @include breakpoint(lg) {
                    max-width: 90%; }
                &-full {
                    width: 100%;
                    height: 100%;
                    background: center / cover no-repeat;
                    display: none;
                    @include breakpoint(sm) {
                        .viewport-wide & {
 } } } } }                            // display: block
        .slider {
            &--prev {
                left: 20px;
                z-index: 300;
                @include breakpoint(sm) {
                    left: -6px; } }
            &--next {
                right: 20px;
                z-index: 300;
                @include breakpoint(sm) {
                    right: -6px; } } }
        .slick {
            &-slider, &-list, &-track {
                height: 100%; }
            &-slide {
 }                // opacity: 0
            &-dots {
                bottom: 30px;
                @include breakpoint(md) {
                    .viewport-wide & {}
                    bottom: 10px; }
                li button:before {
                    color: white;
                    font-size: 7px; } } } }
    &__card {
        position: absolute;
        bottom: calc( 100% + 60px );
        right: 0;
        z-index: 10;
        width: 350px;
        border-radius: 5px;
        @include breakpoint(sm) {
            position: static;
            width: 100%; }
        p {
            margin: 0; }
        &__header {
            padding: 30px;
            border-radius: 5px; }
        &__list {
            list-style: none;
            padding: 30px !important;
            &__item {
                display: flex;
                align-items: center;
                justify-content: space-between; }
            &__label, &__value {
                // margin: 0
                line-height: 15px; }
            &__label {
                font-size: 12px;
                opacity: .7;
                text-transform: capitalize; }
            &__value {
                color: #626262;
                font-size: 14px; } } }
    &__map {
        width: 100%;
        height: 400px; } }
