.hero {
	background: white;
	position: relative;
	.home & {
		.container {
			position: static; } }
	&__title {
		margin-bottom: 0;
		font-weight: 400;
		// font-size: rem(40)
		// @extend h2
		color: #969696;
		letter-spacing: 0;
		// line-height: rem(45)
		@include breakpoint(sm) {
			padding-bottom: $gutter;
			text-align: left; } }
	&__content {
		height: 100%;
		display: flex;
		flex-flow: column;
		justify-content: center;
		position: relative;
		&:before {
			content: '';
			background: #D4DDDE;
			height: 90%;
			width: 2px;
			position: absolute;
			left: -0px;
			top: 5%;
			@include breakpoint(sm) {
				display: none; } }
		p {
			max-width: 300px;
			// border-left: 2px solid $grey-lighter
			margin: 0;
			padding-left: 20px;
			position: relative;
			@include breakpoint(sm) {
				border-left: none;
				border-top: 2px solid $grey-lighter;
				padding: 0;
				padding-top: $gutter; } } }
	&__img {
		position: absolute;
		right: 10%;
		bottom: 0;
		height: 110%;
		width: 300px;
		pointer-events: none;
		background: center / contain no-repeat;
		@include breakpoint(md) {
			display: none; }
		@include breakpoint(dt) {
			right: 0; }
		@include breakpoint(lg) {
			right: 10%; }
		@include breakpoint(xl) {
			right: 20%; } } }

