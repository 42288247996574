@import '../helpers';

.navigation-bar {
    display: flex;
    background-color: white;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    margin: 0;
    height: 100px;
    position: relative;
    // z-index: 200
    transition: z-index 0s 2s;
    @include breakpoint(md) {
        z-index: 200;
        flex-flow: column;
        padding: 0;
        height: 80px; }
    @include breakpoint(sm) {
        height: 70px;
        min-height: 70px; }

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0px;
        box-shadow: 0 1px 1px 1px rgba($grey, 0.2);
        z-index: 10; }
    &.is-loading + main {
        // filter: blur(2px)
        &:before {
            // opacity: 1
 } }            // z-index: 1000
    &.is-loading ~ footer {
 }        // opacity: 0
    .front & {
        background-color: $lightgrey; }
    .slider-is-open & {
        z-index: -1000;
        transition: z-index 0s 0s; }
    &__header {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        background: white;
        z-index: 200;
        @include breakpoint(md) {
            position: static;
            height: 80px;
            min-height: 80px;
            width: 100%; }
        @include breakpoint(sm) {
            height: 70px;
            min-height: 70px; } }
    &__inner {
        height: 100%;
        width: 100%;
        display: flex;
        @include breakpoint(md) {
            transform: translateY(-100%);
            height: auto;
            display: block;
            @include transition($easing: $easeFast);
            visibility: hidden;
            .menu-is-open & {
                transform: none;
                visibility: visible;
                @include transition($easing: $easeFast); } } }
    &__logo {
        background: $green;
        width: 300px;
        height: 100%;
        padding: 27px 30px;
        text-align: center;
        @include breakpoint(md) {
            background: none;
            padding: 0;
            position: absolute;
            top: 0;
            left: 40px;
            height: 80px;
            width: 50px; }
        @include breakpoint(sm) {
            left: 20px;
            width: 40px;
            height: 70px; }
        .icon--unik-logo-text {
            height: 46px;
            @include breakpoint(md) {
                display: none; } }
        .icon--unik-logo {
            display: none;
            height: 100%;
            width: auto;
            @include breakpoint(md) {
                display: block; } }
        &:hover {
            #arrow-left, #arrow-right {
                // transform: rotateZ(360deg)
                transform: scale(1.1);
                transform-origin: center center; } }
        svg {
            overflow: visible;
            #arrow-left, #arrow-right {
                // transform: rotateZ(3deg)
                @include transition;
                // transform: rotateZ(-183deg)
                transform-origin: center;

                // fill: red
                // fill: none
                // stroke: 2px white
                // stroke-width: 2px
 } } }                // stroke-dasharray: 20px
    &__misc {
        @extend .flex-center;
        margin-left: 40px;
        position: absolute;
        left: 100%;
        top: 50%;
        z-index: -1;
        @include breakpoint(md) {
            margin-left: 0;
            position: relative;
            top: 0;
            left: 0;
            height: 100%; } }

    &__current-page {
        font-size: 20px;
        @include breakpoint(sm) {
            font-size: 16px; }
        @include breakpoint(dt) {
            display: none; } }
    &__loader {
        margin-left: 10px;
        height: 25px;
        width: 25px;
        border-left: 3px solid $grey-lighter;
        border-top: 3px solid $grey-lighter;
        border-right: 3px solid $green;
        border-bottom: 3px solid $green;
        border-radius: 50%;
        @include transition;
        animation: spin 1s infinite linear;
        pointer-events: none;
        opacity: 0;
        position: absolute;
        left: 100%;
        display: none !important;  //TODO
        @include breakpoint(md) {
            position: static; }
        .is-loading & {
            opacity: 1; } }
    &__menu {
        display: flex;
        list-style: none;
        height: 100%;
        padding: 0;
        margin: 0 auto;
        @include breakpoint(md) {
            order: 2;
            background: $green;
            flex-flow: column;
            width: 100%;
            height: auto;
            padding: 0;
            border-top: 2px solid $green;
            border-bottom: 2px solid $green; }
        &__item {
            flex: 0 0 auto;
            color: #626262;
            position: relative;
            // padding: 0 10px
            height: 100%;
            text-transform: uppercase;
            display: flex;
            flex-flow: column;
            align-items: center;
            justify-content: center;
            @include breakpoint(md) {
                align-items: flex-start;
                color: white;
                flex-basis: 60px; }
            @include breakpoint(sm) {
                flex-basis: 50px; }
            &--red {
                background-color: $red; }
            &.is-active {
                border-top: 2px solid $green;
                background: transparentize($grey, 0.9); }
            &.is-active &__label {
                border: none; }
            &:not(:first-child) &__label {
                border-left: 1px solid $grey; }
            &__label {
                cursor: pointer;
                // display: block
                padding: 5px 25px;
                text-decoration: none;
                color: inherit;
                font-weight: normal;
                @extend .flex-center;
                @include breakpoint(mdonly) {
                    font-size: 18px; }
                @include breakpoint(md) {
                    display: block;
                    text-align: left;
                    width: 100%;
                    border: none !important;
                    padding: 0 40px;
                    line-height: 60px; }
                @include breakpoint(sm) {
                    padding: 0 30px;
                    line-height: 50px; }
                &.active {
                    color: $green;
                    @include breakpoint(md) {
                        background: $lightgrey; }
                    &:after {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 100%;
                        width: 100%;
                        background: transparentize($grey, 0.9); } } } } }

    &__sub-menu {
        display: none;
        position: absolute;
        top: 70%;
        left: 50%;
        background-color: white;
        border: 1px solid $light-grey;
        border-radius: 3px;
        z-index: 1000;
        list-style: none;
        margin: 0;
        padding: 0;
        opacity: 0;
        width: 200px;
        margin-left: -100px;
        font-size: 14px;

        &:after {
            display: block;
            content: "";
            position: absolute;
            bottom: 100%;
            left: calc(50% - 5px);
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 10px 10px 10px;
            border-color: transparent transparent white transparent; }

        &.is-open {
            display: block;

            // use animation to trigger animation after display block. A simple transition would trigger
            // and simply instantly appear because of toggeling the display property. This only works with "appear"
            // animations and is therefore not triggered while transitioning to display:none
            animation: scaleAppear 0.5s $ease forwards; }


        &__item {
            color: $dark-grey;
            border-bottom: 1px solid $light-grey;
            z-index: 10000;
            &__label {
                padding: 10px 15px;
                text-decoration: none;
                color: inherit;
                display: block; }
            &:last-of-type {
                border-bottom: none; } } }
    &__login {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: auto;
        display: flex;
        align-items: center;
        @include breakpoint(md) {
            position: relative;
            width: 100%;
            height: auto;
            background: $lightgrey; } }
    &__profile {
        position: relative;
        margin: 10px;
        z-index: 50;
        @include breakpoint(md) {
            width: 100%;
            margin: 0; }
        &__login {
            @extend .flex-center;
            position: absolute;
            right: 20px;
            height: 100%;
            .is-logged-in & {
                display: none; } }
        &__inner {
            opacity: 0;
            visibility: hidden;
            .is-logged-in & {
                visibility: visible;
                opacity: 1; } }
        &:hover &__sub-menu {
            opacity: 1;
            z-index: 10;
            pointer-events: all; }
        &__label {
            color: $green;
            font-size: 15px;
            position: relative;
            margin-right: 38px;
            padding: 20px 0;
            z-index: 1;
            cursor: pointer;
            @include breakpoint(md) {
                width: calc(100% - 70px);
                margin: 0;
                border-bottom: 1px solid rgba($dark-grey, 0.2);
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                height: 80px;
                line-height: 80px;
                padding-right: 60px; }
            @include breakpoint(sm) {
                padding-right: 40px;
                height: 70px;
                line-height: 70px; }
            .icon {
                position: absolute;
                right: -20px;
                top: 0;
                height: 100%;
                width: 12px;
                @include breakpoint(md) {
                    right: 30px; }
                @include breakpoint(sm) {
                    right: 20px; } } }
        &__sub-menu {
            position: absolute;
            top: 100%;
            right: 0;
            min-width: 180px;
            z-index: 10;
            list-style: none;
            padding: 0;
            display: flex;
            flex-flow: column;
            width: 100%;
            border-radius: 5px;
            border: 1px solid $border-grey;
            background: white;
            opacity: 0;
            z-index: -1;
            pointer-events: none;
            @include breakpoint(md) {
                width: 100%;
                opacity: 1;
                z-index: 1;
                pointer-events: all;
                position: static;
                background: transparent;
                border: none;
                margin: 0;
                padding: 30px 40px; }
            @include breakpoint(sm) {
                padding: 30px; }
            &:after {
                content: '';
                position: absolute;
                display: inline-block;
                top: -12.5px;
                right: 12px;
                height: 25px;
                width: 24px;
                transform: rotate(45deg);
                transform-origin: 50% 50%;
                background: white;
                border-left: 1px solid $border-grey;
                border-top: 1px solid $border-grey;
                z-index: 1;
                @include breakpoint(md) {
                    display: none; } }
            &__item:not(.active-case) {
                &:hover {
                    a {
                        color: $green; } } }

            &__item.active-case {
                &:hover {
                    a {
                        color: #fff !important;
                        font-weight: 600; } } }
            &__item {
                border-bottom: 1px solid $border-grey;
                @include breakpoint(md) {
                    border: none;
                    padding: 0 10px; }
                &__label {
                    display: block;
                    color: inherit;
                    padding: 10px;
                    cursor: pointer;
                    @include breakpoint(mdonly) {
                        font-size: 18px; }
                    @include breakpoint(md) {
                        padding: 10px 0; } }
                &:last-child {
                    border: none; } } } }
    &__lock {
        background: $grey-lighter;
        height: 100%;
        width: 100px;
        //padding: 30px
        @include transition;
        position: relative;
        @extend .flex-center;
        perspective: 50px;
        cursor: pointer;
        @include breakpoint(md) {
            position: absolute;
            right: 0;
            top: 0;
            z-index: 160;
            height: 80px;
            width: 80px; }
        @include breakpoint(sm) {
            height: 70px;
            width: 70px; }
        .icon--lock {
            top: 50%;
            &, &--bar {
                height: 16px;
                width: 30px;
                position: absolute;
                left: calc(50% - 15px);
                @include breakpoint(md) {
                    width: 20px;
                    left: calc(50% - 10px); } }
            &--bar {
                bottom: 50%;
                transform-origin: 5px 0;
                transition: fill $transition, transform $transition 0.5s;
                @include breakpoint(sm) {
                    bottom: 47%; }
 } }                // transition: fill 0.2, transform 0.2 0.5

        &.is-logged-in .icon--lock--bar {
            transform: rotateY(180deg); }

        &:hover {
            // background: darken($grey-lighter, 10%)
            .icon {
                fill: $darkgreen !important; } } } }
#menu-toggle {
    // position: relative
    @include breakpoint(dt) {
        display: none; }
    position: absolute;
    right: 0px;
    top: 0;
    padding: 0;
    background: none;
    background: $lightgrey;
    height: 80px;
    width: 80px;
    @include breakpoint(sm) {
        height: 70px;
        width: 70px; }
    span {
        width: 60%;
        left: 20%;
        border-bottom: 2px solid $green;
        display: block;
        transition: top 0.2s 0.2s, transform 0.2s;
        position: absolute;
        transform-origin: 50%;
        &:first-child {
            top: 30%; }
        &:nth-child(2) {
            top: 50%; }
        &:last-child {
            top: 70%; } }
    .menu-is-open & {
        span {
            transition: top 0.2s, transform 0.2s 0.2s;
            top: 50% !important;
            z-index: 200;
            &:first-child {
                transform: rotate3d(0,0,1,-45deg); }
            &:nth-child(2) {
                opacity: 0; }
            &:last-child {
                transform: rotate3d(0,0,1,45deg); } } } }
