.clients {
	background: $lightgrey;
	position: relative;
	padding: 40px 0;
	h2 {
		font-weight: 700; }
	.section-title--block {
		padding-left: $gutter*2;
		@include breakpoint(md) {
			padding-left: $gutter; }
		@include breakpoint(sm) {
			padding-left: 0; } }
	input {
		&[type=text], &[type=password] {
			width: 100%; } }
	section {
 }		// padding: $gutter*2
	// select
	// 	width: 45%
	#card-cvv {
		width: 45%;
		min-width: 0; } }

.payment-history {
	color: $darkgreen;
	&__item {
		display: flex;
		flex-flow: row wrap;
		padding: 20px 0;
		border-top: 1px solid $grey-lighter;
		&:last-child {
			border-bottom: 1px solid $grey-lighter; }
		* {
			padding: 2px 0; } }
	&__date {
		font-weight: 700;
		flex-basis: 100%; }
	&__title {
		flex-basis: 100%; }
	&__price {
		// flex-basis: 30%
		min-width: 20%;
		width: auto;
		padding-right: 40px; }
	&__status {
		width: 30%;
		position: relative;
		padding-left: 20px;
		&:before {
			content: '';
			position: absolute;
			top: 15%;
			left: 0;
			height: 70%;
			width: 2px;
			background: $darkgreen; } } }

