$grey: #728589;
$grey-lighter: #D4DDDE;
$light-grey: rgba(212, 220, 222, 0.5);
$lightgrey: #F5F5F5;
$border-grey: #A5ADAE;
$dark-grey: #576668;
$lighter-grey: #E9E9E9;
$bluegrey: #475355;

$red: #D84716;
$pale-white: rgba(212, 220, 222, 0.25);
$beige: #D4DDDE;
$warm: rgba(140, 129, 107, 0.5);
$green: #2FAB66;
$lightgreen: #00D960;
$darkgreen: #196A3D;
$red: #D11A2A;


$base-font-size: 16px;
$base-line-height: 1.3;


$scale: 1.414;

$amount-of-configured-headers: 4;

// Set the font sizes for each of the breakpoints here
$h4-l: rems(20px);
$h4-m: rems(20px);
$h4-s: rems(20px);
$h3-l: rems(26px);
$h3-m: rems(22px);
$h3-s: rems(18px);

$h2-l: rems(32px);
$h2-m: rems(26px);
$h2-s: rems(24px);

$h1-l: rems(40px);
$h1-m: rems(32px);
$h1-s: rems(28px);


// Example of using a scale
// $h3-l: ($scale * $h4-l)
// $h3-m: ($scale * $h4-m)
// $h3-s: ($scale * $h4-s)
// $h2-l: ($scale * $h3-l)
// $h2-m: ($scale * $h3-m)
// $h2-s: ($scale * $h3-s)
// $h1-l: ($scale * $h2-l)
// $h1-m: ($scale * $h2-m)
// $h1-s: ($scale * $h2-s)
$headers: (h1-s: $h1-s, h1-m: $h1-m, h1-l: $h1-l, h2-s: $h2-s, h2-m: $h2-m, h2-l: $h2-l, h3-s: $h3-s, h3-m: $h3-m, h3-l: $h3-l, h4-s: $h4-s, h4-m: $h4-m, h4-l: $h4-l);

$h1-line-height: 1.1;
$h2-line-height: 1.2;
$h3-line-height: 1.3;
$h4-line-height: 1.3;

$line-heights: (h1: $h1-line-height, h2: $h2-line-height, h3: $h3-line-height, h4: $h4-line-height);

$link-color: $green;
$link-decoration: none;
$link-hover-color: darken($green, 20%);
$link-hover-decoration: none;


$grid-columns: 12;
$gutter: 20px;
$breakpoint-s: 480px;
$breakpoint-m: 768px;
$breakpoint-l: 1024px;
$breakpoint-xl: 1200px;
$breakpoints: (s: $breakpoint-s, m: $breakpoint-m, l: $breakpoint-l, xl: $breakpoint-xl);

$xs: 320;
$sm: 767;
$md: 1024;
$dt: 1025;
$md-l: 1350;
$lg: 1440;
$xl: 1920;

$mediaquerries: ( xs: "max-width: #{$xs}px", sm: "max-width: #{$sm}px",sm-l: "max-width: #{$sm}px) and (orientation: landscape", xl-m: "min-width: #{$sm}px) and (max-width: #{$md-l}px", md: "max-width: #{$md}px", mdonly: "min-width: 768px) and (max-width: #{$md}px", dt: "min-width: #{$dt}px", lg: "min-width: #{$lg}px", xl: "min-width: #{$xl}px" );



$button-font-size: 14px;

$easeFast: cubic-bezier(0.23, 1, 0.32, 1);

$transition: 0.3s;
$transitionSlow: 0.8s;
$easing: cubic-bezier(.77, 0, .175, 1);
$ease: $easeFast;
$easing: $easeFast;

