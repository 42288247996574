.stacked-images {
  height: 600px;
  width: 350px;
  position: relative;
  margin: auto;
  //animation: scaleAppear 1s ease forwards
  //display: flex
  //flex: 0 0 auto
  @include breakpoint(md) {
    & {
      width: 300px; } }
  >div {
    width: 350px;
    max-width: 100%;
    position: absolute;

    top: 60px;
    @include breakpoint(md) {
      & {
        width: 300px; } } } }


