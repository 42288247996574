.profile {
    background: $lightgrey;
    position: relative;
    padding: 40px 0;
    input {
        &[type=text], &[type=password] {
            width: 100%; } }
    section {
 }        // padding: $gutter*2

    // select
    //  width: 45%
    #card-cvv {
        width: 45%;
        min-width: 0; } }

.payment-form {
    input, select {
        min-width: 100px;
        &:focus {
            outline: 1px solid $green; } }
    &__card-type {
        position: absolute;
        right: 20px;
        height: 20px;
        opacity: .5;
        text-transform: capitalize;
        bottom: 30px; } }

.payment-history {
    color: $darkgreen;
    &__item {
        display: flex;
        flex-flow: row wrap;
        padding: 20px 0;
        border-top: 1px solid $grey-lighter;
        &:last-child {
            border-bottom: 1px solid $grey-lighter; }
        * {
            padding: 2px 0; } }
    &__date {
        font-weight: 700;
        flex-basis: 100%; }
    &__title {
        flex-basis: 100%; }
    &__price {
        // flex-basis: 30%
        width: 20%; }
    &__status {
        width: 30%;
        padding-left: 40px;
        position: relative;
        &:before {
            content: '';
            position: absolute;
            top: 15%;
            left: 0;
            height: 70%;
            width: 2px;
            background: $darkgreen; } } }

.myselfsale {
    ul {
        padding: 10px 0;
        list-style-type: none; }
    li {
        list-style-type: none; }
    &__documents {}
    &__document {
        margin-bottom: 20px;
        font-size: 14px; }
    &__label {
        display: block;
        margin: 0;
        padding: 0; }
    &__item {
        padding: 10px 0;
        border-top: 1px solid rgba( black, 0.2);
        border-bottom: 1px solid rgba( black, 0.2);
        display: flex;
        flex-flow: flow;
        justify-content: space-between; }
    &__filename {
        color: black;
        line-height: 20px; }
    &__remove {
        color: $red;
        padding-right: 25px;
        height: 20px;
        appearance: none;
        border: none;
        background: none;
        position: relative;
        cursor: pointer;
        &:hover, &:focus {
            box-shadow: none; }
        &:after, &:before {
            content: '';
            height: 2px;
            width: 20px;
            position: absolute;
            right: 0;
            top: 50%;
            background: $red;
            transform: rotate(45deg); }
        &:after {
            transform: rotate(-45deg); } }

    &__image {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        align-items: center;
        // margin-bottom: 15px
        padding: 20px 0;
        border-top: 1px solid rgba( black, 0.2);
        min-width: 400px;
        @include breakpoint(md) {
            min-width: 100px;
            width: 100%; }
        &::last-child {
            border-bottom: 1px solid rgba( black, 0.2); }
        &__img {
            height: 100px;
            width: 150px;
            background: center / cover no-repeat; } } }
