
.number {
    font-weight: 700;
    display: block;
    &--big {
        font-size: 50px;
        line-height: 100px;
        color: white; } }


.bordered-columns {
    >* {
        &:not(:first-child) {
            border-left: 1px solid $grey-lighter;
            padding-left: $gutter*1;
            //+breakpoint(lg)
            //    padding-left: $gutter*3
            //+breakpoint(md)
            //    padding-left: 20px
            @include breakpoint(sm) {
                border-left: none;
                padding-left: 10px;
                padding-right: 10px; } }
        &:first-child {
            padding-right: $gutter*1;
            //+breakpoint(lg)
            //    padding-right: $gutter*3
            //+breakpoint(md)
            //    padding-right: 10px
            @include breakpoint(sm) {
                padding-right: 10px; } }
        .section:first-child {
            padding-top: 0; }
        // &:first-child
 } }        //     border-left: none !important

.banner {
    position: absolute;
    top: 0px;
    right: 50px;
    padding: 15px 0;
    width: 200px;
    text-align: center;
    color: white;
    font-weight: 700;
    transform: translate(50%, 50%) rotate(45deg);
    background-image: linear-gradient(-141deg, #08F671 0%, #00D960 37%);
    box-shadow: 0 2px 5px 0 rgba(126, 126, 126, 0.5);
    text-transform: uppercase;

    &.selfsale {
        background-image: linear-gradient(-141deg, #ffee00 0%, #d9b804 37%); }
    &.reserved {
        background-image: linear-gradient(-180deg, #4464c4 0%, #4350ca 100%); }
    &.sold {
        background-image: linear-gradient(-180deg, #f50307 0%, #eb3f39 100%); }
    &.open-house {}

    &.banner-swap-sent {
        background-image: linear-gradient(-141deg, #ffee00 0%, #d9b804 37%); }
    &.banner-swap-received {
        background-image: linear-gradient(-141deg, #ffee00 0%, #d9b804 37%); } }


.bg-img {
    min-height: 300px;
    height: 60vh;
    background: center / cover no-repeat;
    width: 100%;
    position: relative;
    &--large {}
    &--green {
        &:after {
            content: '';
            position: absolute;
            @include coverall;
            background: linear-gradient(to bottom, rgba($green, 0.2) 0%, transparent 100%); } } }
.card {
    box-shadow: 0px 7px  0px 0px white, 0 0 11px 0px rgba(185,185,185,0.50); }

.content-card {
    max-width: 1200px;
    margin: -50px auto 40px;
    background: white;
    position: relative;
    padding: $gutter*3;
    padding-right: $gutter*1;
    z-index: 10;
    box-shadow: 0px 7px  0px 0px white, 0 0 11px 0px rgba(185,185,185,0.50);
    &:before, &:after {
        // content: ''
        position: absolute;
        height: 60%;
        top: 0;
        left: 0;
        width: 0px;
        box-shadow: 0 0 11px 1px rgba(185,185,185,0.50); }
    &:after {
        right: 0;
        left: auto; }
    @include breakpoint(md) {
        margin: -50px 20px 20px; }
    @include breakpoint(sm) {
        margin: 0;
        box-shadow: none;
        padding: 40px 0px;
        margin-bottom: 0;
        background: none; } }

.price-bubble {
    position: relative;
    display: inline-block;
    height: 42px;
    min-width: 200px;
    width: auto;
    // background: $lightgreen
    border-radius: 25px;
    padding: 0 17px;
    color: white;
    font-weight: 700;
    font-size: 26px;
    line-height: 42px;
    text-align: center;
    .icon--price-bubble {
        position: absolute;
        bottom: -6px;
        left: calc( 50% - 10px); }
    &:after {
        content: ',-'; }
    &--big {
        height: auto;
        padding: 25px 30px 18px 30px;
        margin-bottom: 40px;
        .icon--price-bubble {
            height: 40px;
            width: 40px;
            left: calc( 50% - 20px);
            bottom: -23px; }
        .number {
            font-size: 92px;
            line-height: 60px;
            margin-bottom: 12px;
            margin-top: 10px;
            &.star {
                &:after {
                    content: "*";
                    position: absolute;
                    margin-left: -20px;
                    margin-top: -18px;
                    font-size: 40px; } } }


        &:after {
            content: none; } } }

.sidebar {
    // padding-left: 50px

    @include breakpoint(lg) {
        min-height: 500px; }
    h3, h4 {
        font-weight: 400;
        color: $lightgreen; }
    &__column {
        margin-bottom: 40px; }
    ul {
        list-style: none;
        padding-left: 0; }
    li {
        border-bottom: 1px solid lightgrey;
        font-size: 14px;
        line-height: 18px;
        padding: 10px 0;
        color: $dark-grey;
        a {
            cursor: pointer;
            // color: $dark-grey
            // &:hover
 } } }            //     color: $lightgreen

.checklist {
    padding: 0;
    &-item {
        width: 100%;
        border-bottom: 1px solid lightgrey;
        display: flex;
        flex-flow: row;
        justify-content: flex-start;
        align-items: center;
        padding: 20px 0;
        text-align: left;
        &.counter {
            counter-increment: li;
            &:before {
                content: counter(li);
                font-size: 26px;
                line-height: 26px;
                font-weight: 300;
                color: $green;
                height: 20px;
                width: 20px;
                margin-right: 20px; } }
        svg {
            height: 20px;
            width: 20px;
            margin-right: 20px;
            stroke: $green;
            stroke-dashoffset: 0;
            stroke-width: 3px;
            flex: 0 0 20px; } } }

.info-list {
    list-style: none;
    padding: 10px !important;
    margin-bottom: 20px !important;
    position: relative;
    background: $lightgrey;
    border-radius: 4px;
    box-shadow: 0 3px 3px 0px lightgrey;
    li {
        display: flex;
        align-items: left;
        align-text: right !important;
        justify-content: space-between;
        .label, .value {
            // margin: 0
            line-height: 17px; }
        .label {
            font-size: 12px;
            opacity: .7;
            text-align: left; }
        .value {
            color: #626262;
            font-size: 14px; } } }

.info-list-left-align {
    list-style: decimal inside none;
    padding: 10px !important;
    padding-left: 30px;
    margin-bottom: 20px !important;
    position: relative;
    background: $lightgrey;
    border-radius: 4px;
    box-shadow: 0 3px 3px 0px lightgrey;
    display: table;
    counter-reset: foo;
    li {
        counter-increment: foo;
        font-size: 12px;
        opacity: .7;
        text-transform: capitalize;
        text-align: left;
        display: table-row;
        .value {
            color: #626262;
            font-size: 14px; }
        &:before {
            content: counter(foo) ".";
            display: table-cell /* aha! */;
            text-align: right;
            padding-right: 5px; } } }

.file-list {
    list-style: none;
    margin-left: 0px;
    padding: 0px;
    li {
        margin-bottom: 10px;
        a {
            text-align: left;
            display: flex;
            align-items: center;
            //text-align: center
            padding: 0;
            vertical-align: middle;
            word-break: break-all;
            svg {
                margin-right: 10px;
                flex: 0 0 auto; }
            span {
                flex: 1 1 auto; } } } }






