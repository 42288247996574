.icon {
	display: inline-block;
	max-height: 100%;
	max-width: 100%;
	@include transition;
	&--padded {
		padding: 20px; }
	&--green {
		fill: $green;
		stroke: $green !important; }
	&--lightgreen {
		fill: $lightgreen; }
	&--darkgreen {
		fill: $darkgreen; }
	&--black {
		fill: black; }
	&--white {
		fill: white;
 }		// stroke: white
	&--chevron {
		fill: #7B7B7B; }
	&--arrow {
		stroke-linejoin: round;
		stroke-linejoin: square;
		stroke-width: 2px;
		stroke: rgba(black, 0.8);
		fill: none; }
	&--photo {
		height: 45px;
		width: 45px;
		stroke: black;
		// path
		// stroke-dasharray: 100% 100%
		// stroke-dasharray: attr(data-length)
		// stroke-dashoffset: 100px
 }		// animation: stroke 1s infinite alternate linear
	&--add {
		height: 33px;
		width: 33px; }
	&--check {
		fill: none;
		stroke: rgba(black, 0.6);
		stroke-width: 2px;
		stroke-dasharray: 46px;
		stroke-linecap: square;
		stroke-dashoffset: 48px; }
	&--bordered-arrow {
		height: 45px;
		width: 45px;
		padding: 14px;
		border-radius: 50%;
		background: $bluegrey;
		background: #3F4849;
		stroke: white;
		stroke-linejoin: round;
		fill: none;
		@include breakpoint(sm) {
			background: none; }
		&.lightgreen {
			background: $lightgreen; } }
	&--price-bubble {
		position: absolute;
		bottom: -6px;
		left: calc( 50% - 10px);
		height: 10px;
		width: 20px; }
	&--floor-plan {
		fill: none;
		stroke: white;
		stroke-width: 2px; }
	&--gallery {
    stroke-width: 1px; }
	&--documents {
		fill: none;
		stroke: white;
		stroke-width: 1.5px; }
	&--house {}
	&--upload {
		&-arrow {}
		&-bar {} }
	&--question {
		height: 30px;
		width: 30px;
		background: white;
		border-radius: 50%;
		padding: 10px;
		cursor: pointer;
		will-change: transform;
		@include breakpoint(dt) {
			&:hover {
				transform: scale(1.2); } } }
	&--map {}
	&--doc {
		height: 30px;
		width: 30px; } }
