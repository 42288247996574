@keyframes slide-in-left {
  from {
    left: -400px; }
  to {
    left: 0; } }

@keyframes slide-in-bottom {
  from {
    bottom: -400px; }
  to {
    bottom: 0; } }


@media (prefers-reduced-motion: no-preference) {
  .slide-animation {
    animation: slide-in-left 1s 1;
    @include breakpoint(md) {
      animation: slide-in-bottom 1s 1; } } }


.sticky-mobile-cta {
  pointer-events: none;
  background-color: #00A64900;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  text-align: center;
  position: fixed;
  left: 0;
  top: 20vh;
  background-color: #2FAB66;
  padding: 13px;
  z-index: 1000;
  width: 370px;
  h3 {
    margin-bottom: 0.2em;
    color: white; }
  p {
    color: white;
    font-size: 16px; }


  @include breakpoint(md) {
    position: fixed;
    top: auto;
    bottom: 0px;
    left: 0px;
    width: 100vw;
    height: 190px;
    justify-content: space-around; }

  @include breakpoint(sm); }
