$button-drop-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.3);


.swapbutton {
  // @extend .flex-center
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 50px;
  pointer-events: all;
  height: 50px;
  // text-transform:
  border-radius: 5px;
  border: none;
  cursor: pointer;
  background: $lightgreen;
  appearance: none;
  -webkit-appearance: none;
  color: white;
  // display: block
  outline: 0;
  width: 100px;
  max-width: 100%;
  font-weight: 700;
  border-radius: 5px;
  // box-shadow: 0 1px 0px 0px $grey

  &:first-letter {
    text-transform: uppercase; }
  @include breakpoint(dt);

  @include breakpoint(md) {
    min-width: 180px;
    padding: 0 20px;
    // width: auto
    display: block;
    display: inline-block;
    max-width: 50vw;
    max-width: 100%; }
  @include breakpoint(sm) {
    min-width: 140px; }

  &.is-pressed {
    transform: scale(0.9); }
  &.is-disabled, &[disabled] {
    pointer-events: none;
    cursor: not-allowed;
    opacity: .4; }

  &--grey {
    background-color: $dark-grey;

    &:hover {
      background-color: lighten($dark-grey, 10%); } }

  &--white {
    background-color: white;
    color: $dark-grey;
    box-shadow: $button-drop-shadow, inset 0 0 0 1px $dark-grey;

    &:hover {
      background-color: white;
      color: $dark-grey; } }

  &--green {
    background-color: $green;
    &:hover {
      background-color: lighten($green, 10%); } }
  &--darkgreen {
    background-color: $darkgreen;
    box-shadow: 0 1px 0px 0px rgba(black, 0.6);
    box-shadow: none;
    &:hover {
      background-color: lighten($darkgreen, 10%);
      box-shadow: none; } }
  // box-shadow: 0 2px 0px 0px rgba(black, 0.6)
  &--clear {
    background: transparent;
    color: $dark-grey;
    box-shadow: $button-drop-shadow, inset 0 0 0 1px $dark-grey;
    &:hover {
      background: transparent;
      color: $dark-grey; } }
  &--invert {
    background: $lightgrey;
    color: $lightgreen;
    // box-shadow: 0 1px 0 0 #C5C5C5
    border-radius: 5px; }


  &.yes {
    background-color: #00D960;
    @include shadow;
    @include transition;
    &:hover {
      @include shadowBig; } }
  &.no {
    background-color: red;
    @include shadow;
    @include transition;
    &:hover {
      @include shadowBig; } }

  &.later {
    margin-top: 10px;
    height: auto;
    line-height: initial;
    color: white;
    background-color: #00000000; } }
