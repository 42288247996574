.card {
	position: relative;
	min-height: 200px;
	background: $lightgrey;
	border-radius: 4px;
	box-shadow: 0 3px 2px 0px lightgrey;
	padding-bottom: $gutter;

	// +shadow
	@include breakpoint(sm) {
		margin-bottom: $gutter*3; }
	&__header {
		background: $bluegrey;
		color: white;
		padding: $gutter;
		border-radius: 4px;
		@include breakpoint(mdonly) {
			padding-top: $gutter*2; }
		h3 {
			font-size: 28px; }
		p {
			font-size: 14px;
			line-height: 18px;
			color: white;
			opacity: .75; } }
	&__list {
		padding: 0; }
	.checklist-item {
		padding: 20px; }
	&__price-bubble {
		position: absolute;
		top: -15px;
		right: 20px;
		@include breakpoint(sm) {
			top: -25px;
			right: 10px; } }
	.button {
		margin: 30px auto 20px;
		display: block;
		// text-transform: uppercase
		min-width: 200px;
		@include breakpoint(md) {
			min-width: 150px;
			width: 80%; } } }

.card-hero {
	padding: $gutter*3 $gutter*4;
	width: 500px;
	@include shadow;
	@include breakpoint(sm) {
		width: 100%;
		padding: $gutter*3 $gutter*2; }
	&.lightgreen {
		background: $lightgreen; }
	&__title {
		text-align: center;
		font-weight: 700;
		font-size: 40.5px;
		color: $darkgreen;
		line-height: 0;
		padding: 15px 0px; }
	&__list {
		padding: 0;
		margin-top: 40px;
		&__item {
			width: 100%;
			border-bottom: 1px solid rgba(white, 0.3);
			display: flex;
			flex-flow: row;
			justify-content: flex-start;
			align-items: center;
			padding: 20px 0;
			color: white;
			&:first-child {
				border-top: 1px solid rgba(white, 0.3); }
			svg {
				height: 20px;
				width: 20px;
				margin-right: 10px;
				stroke: white;
				stroke-dashoffset: 0;
				stroke-width: 3px;
				flex: 0 0 20px; } } }

	button {
		width: 100%;
		text-align: center;
		&.darkgreen {
			background: $darkgreen;
 } }			// text-transform: uppercase

	a {
		width: 100%;
		text-align: center;
		font-size: 15px;
		margin: 10px 0 0 0;
		&.darkgreen {
			background: $darkgreen;
 } } }			// text-transform: uppercase



