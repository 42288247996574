section.slider {
	background: $bluegrey;
	padding: $gutter*5 0;
	position: relative;
	@include breakpoint(md) {
		padding: $gutter*3 $gutter*1 $gutter*5; }
	@include breakpoint(sm) {
		padding: $gutter*3 0 $gutter*5; } }
.slider {
	h2 {
		font-weight: 400;
		color: white; }
	.button {
		margin: 0;
		float: right;
		@include breakpoint(md) {
			float: none; } }
	&__container {
		position: relative;
		margin-top: $gutter*2;
		// margin-left: -$gutter
		// margin-right: -$gutter
		// width: 100%
 }		// max-width: calc(100vw - 200px)
	&__slide {
		// flex: 1 0 25%
		// width: 25%
		// padding: 0 $gutter
		min-height: 400px;
		border-right: 1px solid rgba(grey, 0.3);
		cursor: grab;
		&:focus, &:active {
			cursor: grabbing; }
		&:last-child {
			border-right: none; }
		&__img__wrapper {
			margin-bottom: $gutter*2;
			width: 100%;
			height: 200px;
			overflow: hidden;
			cursor: pointer;
			position: relative; }
		&__img {
			@include coverall;
			//position: relative
			transform-origin: 50% 50%;
			@include transition;
			will-change: transform;
			&:before {
				@include coverall;
				background-image: url("/assets/img/img-no-image.jpg");
				background-size: cover;
				background-position: center;
				z-index: -1;
				content: "";
				position: absolute;
				@include transition;
 }				//transform-origin: 50% 50%
			&:hover:before {
				transform: scale(1.1); } }



		&__info {
			border-bottom: 1px solid rgba(grey, 0.3);
			color: white;
			padding: 5px 0; }
		&--price {
			color: $lightgreen;
			font-weight: 700;
			border: none;
			margin-top: $gutter*1.5;
			@include breakpoint(md) {
				margin-top: $gutter; } } }
	&--prev, &--next {
		top: auto;
		bottom: -80px;
		position: absolute;
		cursor: pointer;
		will-change: transform;
		top: calc( 50% - 22px);
		bottom: auto;
		display: block;
		@include breakpoint(sm) {
			width: 55px;
			height: 55px; } }

	&--prev {
		right: 51%;
		transform: rotateZ(180deg);
		&:hover {
			transform: scale(1.1) rotateZ(180deg); }
		@include breakpoint(lg) {
			right: auto;
			left: -80px; }
		@include breakpoint(xl) {
			left: -100px; } }

	&--next {
		right: -80px;
		left: auto;
		&:hover {
			transform: scale(1.1); }

		@include breakpoint(xl) {
			right: -100px; } }

	.slick {
		&-slider {
			@extend .col-s-12; }
		&-list {
			margin-bottom: 40px;
			@include breakpoint(lg) {
				margin-bottom: 60px; } }
		&-slide {
			padding: 10px; }
		&-dots {
			li button {
				&:before {
					color: white;
					font-size: 7px; }
				&:focus {
					outline: 0;
					box-shadow: none; } } } } }
