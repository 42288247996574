@keyframes scaleAppear {
    0% {
        opacity: 0;
        transform: scale(0.7); }
    100% {
        opacity: 1;
        transform: scale(1); } }

@keyframes stroke {
	100% {
		stroke-dashoffset: 0; } }

@keyframes rotate180 {
    100% {
        transform: rotateY(180deg); } }

@keyframes spin {
	100% {
		transform: rotateZ(360deg); } }

@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translate3d(0,-20px,0); }
    100% {
        opacity: 1;
        transform: translate3d(0,0px,0); } }
@keyframes fadeIn {
    0% {
        opacity: 0; }
    100% {
        opacity: 1; } }

@keyframes search {
    0% {
        opacity: 1;
        transform: scale(1); }
    50% {
        transform: scale(2);
        opacity: 0; }
    70% {
        transform: scale(2);
        opacity: 0; } }

@keyframes slightMoveDownAppear {
    0% {
        transform: translate3d(0, 30px, 0); }
    100% {
        transform: translate3d(0, 0, 0); } }


.a-fade-in-down {
    animation: fadeInDown 1s ease forwards; }
