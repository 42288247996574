.tooltip {
  position: relative; }

.tooltip>.tooltiptext {
  visibility: hidden;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 78%;
  left: 50%;
  margin-left: -21px;
  opacity: 0;
  transition: opacity 0.3s; }


.tooltip>.tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent; }


.tooltip:hover>.tooltiptext {
  visibility: visible;
  opacity: 1; }
