section.image-block-full {
	min-height: 700px;
	position: relative;
	margin-top: 100px;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	.home & {
		margin-top: 0;
		margin-bottom: 60px; }
	@include breakpoint(sm) {
		margin-top: 0;
		min-height: initial; }
	.card-hero {
		transform: translate($gutter*2, -$gutter*2);
		.home & {
			transform: translate($gutter*2, $gutter*2); }
		@include breakpoint(mdonly) {
			transform: translate(0, -$gutter*2) !important;
			margin: 0 auto;
			.home & {
				transform: translate(0, $gutter*2) !important; } }
		@include breakpoint(sm) {
			transform: none !important; } } }

section.image-block-half {
	min-height: 440px;
	background-position: left center;
	background-size: contain;
	background-repeat: no-repeat;
	@include breakpoint(md) {
		background-size: 55%;
		background-position: left bottom; }
	@include breakpoint(sm) {
		background-image: none !important; } }
.image-block-half {
	&__content {
		@extend .flex-vertical;
		min-height: 400px;
		h2 {
			line-height: 35px; } } }
