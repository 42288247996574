html {
  margin: 0;
  padding: 0;
  width: 100%;
  color: $dark-grey;
  &.slider-is-open {
    overflow: hidden; }
  @include breakpoint(sm) {
    overflow-x: hidden; } }

body {
  width: 100%;
  margin: 0;
  padding: 0;
  height: 100vh;

  overflow-x: hidden;
  @include breakpoint(sm) {
    overflow-x: hidden; } }
// &.slider-is-open
//     overflow-y: hidden
//     position: fixed


*, *:before, *:after {
  box-sizing: border-box; }

video {
  width: 100%;
  height: auto; }


.main {
  @include transition($easing: $easeFast);
  .slider-is-open & {
    overflow-x: visible; }
  &:before {
    content: '';
    background: rgba($green, 0.1);
    top: 100px;
    left: 0;
    position: fixed;
    height: 100vh;
    width: 100%;
    @include transition($easing: $easeFast);
    opacity: 0;
    z-index: -100; }
  .menu-is-open & {
    @include transition($easing: $easeFast);
    @include breakpoint(md) {
      transform: translateY(324px); }
    @include breakpoint(sm) {
      transform: translateY(270px); } } }

.section {
  padding: $gutter*2;
  @include breakpoint(md) {
    padding: $gutter*1.5 $gutter*1; }
  @include breakpoint(sm) {
    padding: $gutter*1.5 0; }
  &--first {
    padding-top: 0; }
  &--loading {
    height: 50vh;
    background: rgba($lightgreen, 0.1);
    @include ext(flex-center);
    // animation: fadeIn 0.3s $ease
    &:after {
      content: '';
      // margin-left: 10px
      height: 35px;
      width: 35px;
      border-left: 3px solid $grey-lighter;
      border-top: 3px solid $grey-lighter;
      border-right: 3px solid $green;
      border-bottom: 3px solid $green;
      border-radius: 50%;
      @include transition;
      animation: spin 1s infinite linear;
      pointer-events: none;
      position: absolute;
      left: calc(50% - 17.5px);
      // opacity: 0
      .is-loading & {
        opacity: 1; } } } }

.subsection {
  padding-bottom: $gutter; }

.section {
  &-title {
    position: relative;
    font-weight: 400;
    margin-bottom: $gutter*3;
    margin-top: $gutter*1;
    &--block {
      @extend .section-title;
      display: block; } }
  &-subtitle {
    color: $dark-grey; } }

.block {
  background: $dark-grey;
  min-height: 3rem;
  margin-bottom: $gutter;
  padding: 1rem 1rem 0 1rem;
  border-radius: 3px;
  .block {
    background-color: $light-grey;
    min-height: 1rem; } }

.html-content {
  position: relative;
  overflow: hidden;
  h1, h2, h3, h4 {
    color: $green; }

  &.collapsed {
    &:before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background: linear-gradient(transparent 90%, white); } } }




