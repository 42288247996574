.u-white-text {
    color: white; }
.u-darkgreen-text {
    color: $darkgreen; }
.u-green-text {
    color: $green; }
.u-lightgreen-text {
    color: $lightgreen; }

.u-darkgreen {
    background-color: $darkgreen; }
.u-green {
    background-color: $green; }
.u-lightgreen {
    background-color: $lightgreen; }
.u-lightgrey {
    background-color: #F3F3F3; }

.u-light-text {
    font-weight: 300; }

.u-center-text {
    text-align: center; }
.u-center {
    margin: 0 auto;
    display: block; }

.u-no-margin {
    margin: 0; }

.u-full-height {
    min-height: 80vh; }
.flex-center {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    &>.container {
        flex-grow: 1; } }

.flex-evenly {
    display: flex;
    flex-flow: row;
    justify-content: space-evenly;
    align-items: center;
    &>.container {
        flex-grow: 1; } }


.flex-vertical {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start; }
.flex-column {
    display: flex;
    flex-flow: column;
    align-items: center; }

.hide {
    display: none; }

.animate--in {
    animation: fadeIn 0.3s $ease; }
.animate--down, .animate--up {
    // animation: fadeInDown 0.3s $ease
    animation:  slightMoveDownAppear 0.5s $easeFast;
    @include breakpoint(lg) {
        min-height: 360px; } }

.animate--slow {
    animation-duration: 2s; }

.animate--delay-1 {
    animation-delay: 1s; }

.animate--delay-2 {
    animation-delay: 2s; }

