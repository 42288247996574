@import 'libraries/slick';
@import 'libraries/slick-theme';
@import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css';

@import 'partials/helpers';
@import 'partials/config';
@import 'partials/reset';
@import 'partials/animations';
@import 'partials/base';
@import 'partials/global';
@import 'partials/typography';
@import 'partials/grid';
@import 'partials/utils';

@import 'partials/components/components';
@import 'partials/components/navigation-bar';
@import 'partials/components/footer';
@import 'partials/components/buttons';
@import 'partials/components/icons';
@import 'partials/components/inputs';
@import 'partials/components/hero';
@import 'partials/components/search';
@import 'partials/components/slider';
@import 'partials/components/cards';
@import 'partials/components/login-window';
@import 'partials/components/modals';
@import 'partials/components/quotes';
@import 'partials/components/apartments-grid';
@import 'partials/components/image-block';
@import 'partials/components/map';
@import 'partials/components/errors';
@import 'partials/components/foldable';
@import 'partials/components/tooltip';
@import 'partials/components/swap-process';
@import 'partials/components/circle';
@import 'partials/components/tabify';
@import 'partials/components/sticky-mobile-cta';
@import 'partials/components/swapbutton';
@import 'partials/components/stacked-images';





@import 'partials/pages/profile';

@import 'partials/pages/clients';
@import 'partials/pages/sale';
@import 'partials/pages/case';
@import 'partials/pages/buy';
@import 'partials/pages/contact';
@import 'partials/pages/partners';


