

@function breakpoint($name) {
    @return map-get($breakpoints, $name); }

@mixin breakpoint($size) {
  @media (map-get($mediaquerries, $size)) {
    @content; } }

@function rems($size) {
    $remSize: $size / $base-font-size;
    @return #{$remSize}rem; }

@function header($name) {
    @return map-get($headers, $name); }

@function lineHeight($name) {
    @return map-get($line-heights, $name); }

@mixin transition($prop: all, $time: $transition, $easing: $easing, $delay: 0s ) {
  transition: $prop $time $easing $delay; }

@mixin transitionSlow($prop: all, $time: $transitionSlow, $easing: $easing, $delay: 0s ) {
  transition: $prop $time $easing $delay; }

@mixin coverall {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

@mixin ext($name) {
  @extend .#{$name} !optional; }

@mixin shadow {
  box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3); }

@mixin shadowBig {
  box-shadow: 0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12), 0 3px 5px -1px rgba(0,0,0,0.3); }
