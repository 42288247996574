.reminder-box {
  box-sizing: border-box;
  border: 1px solid #2fab66;
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  max-width: 800px;
}

.reminder-box-icon {
  width: 100px;
  height: 100px;
  margin: 10px;

  flex-shrink: 0;
}

.reminder-box-content {
}

.reminder-box-price {
  margin-top: 16px;
}

.reminder-box-strong {
  color: #2fab66;
  font-family: Chivo;
  font-size: 20px;
  font-weight: bold;
  line-height: 28px;
}
