.map {
    height: 100%;
    width: 100%;
    &__pin {
        height: 5px;
        width: 5px;
        background: black;
        border-radius: 50%;
        position: relative;
        .icon {
            height: 40px;
            width: 40px;
            max-width: 40px;
            max-height: 40px;
            position: absolute;
            top: -1px;
            left: 50%;
            transform: translate(-50%, -100%); }
        &__text {
            font-size: 16px;
            color: black;
            text-shadow: 0 0 1px white;
            font-weight: 500;
            position: absolute;
            top: -5px;
            left: 100%;
            display: flex; }
        &__street, &__streetnumber {
            margin-left: 5px;
            float: left; } } }
