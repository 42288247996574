.gradient-fade {
  position: relative;
}

.gradient-fade:after {
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 100%;
  content: "";
  background: linear-gradient(to top,
     rgba(255,255,255, 1) 10%,
     rgba(255,255,255, 0) 90%
  );
  pointer-events: none; /* so the text is still selectable */
}