.footer {
  background: $bluegrey;
  animation: slightMoveDownAppear 0.5s cubic-bezier(0.23, 1, 0.32, 1);

  .container {
    padding: 60px 8vw;
    max-width: initial; }

  .icon--unik-logo {
    margin-bottom: 20px;
    display: block;
    height: 25px;
    width: 150px; }

  .content {
    color: white;
    opacity: .7;
    @include breakpoint(md) {
      margin-bottom: 40px; } }

  h4 {
    color: $green;
    margin-top: 0;
    margin-bottom: 20px;
    line-height: 25px;
    @include breakpoint(sm) {
      text-align: left; } }

  p, a {
    opacity: 0.7;
    font-family: Montserrat;
    font-family: Chivo;
    font-size: 12px;
    color: #FFFFFF;
    letter-spacing: 0;
    line-height: 20px;
    margin-top: 0; }

  a {
    &:hover {
      opacity: 1; } }


  address {
    font-style: normal;
    // +breakpoint(sm)
    // 	margin-bottom: 40px
    p {
      margin: 0;
      margin-bottom: 0px;
      padding: 0; }

    .zip {
      margin-bottom: 10px; } }

  .quik {
    &__links {}

    &__link {
      margin-bottom: 5px;
      border-bottom: 1px solid transparentize(white, 0.5);

      //&:hover
      //  a
 } } }      //    opacity: 1

.credit-cards {
  &__card {
    margin-right: 10px;
    height: 30px; } }

.copyright {
  background: #393F40;
  padding: 40px 8vw;
  // +breakpoint(md)
  // 	padding: 40px 20px 50px
  // +breakpoint(sm)
  // 	padding-bottom: 100px
  a, p {
    opacity: 0.38;
    font-size: 11px;
    color: #FFFFFF;
    letter-spacing: 0;
    line-height: 20px; }

  a {
    opacity: 1; } }
// +breakpoint(mdonly)
// 	float: right
.elevator {
  display: none;
  border: none;
  background: none;
  position: absolute;
  bottom: 20px;
  right: 20px;
  opacity: 0.38;
  font-size: 11px;
  color: #FFFFFF;

  svg {
    transform: rotate(180deg);
    width: 14px;
    margin-left: 5px; }

  .front & {
    display: block; } }
