.error-message {
    color: $red;
    position: absolute;
    width: 100%;
    text-align: center;
    top: 20px;
    z-index: 100;
    .payment-component & {
        position: static;
        margin: 10px 0; } }
